import React from "react";
import "./Button.scss";

const Button = ({ label, onClick, variant }) => {
    const buttonClass =
        variant === "transparent"
            ? "button registration-link button--transparent"
            : "button registration-link";

    return (
        <div className="button-wrapper">
            <a
                onClick={
                    onClick
                        ? (e) => {
                              e.preventDefault();
                              onClick();
                          }
                        : null
                } // Проверяем наличие onClick
                className={buttonClass}
                href="#registration-anchor"
            >
                {label}
            </a>
        </div>
    );
};

export default Button;
