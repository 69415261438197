// Notification.js
import React from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import "./Notification.scss";

const Notification = () => (
    <div>
        <ToastContainer transition={Slide} />
    </div>
);

export const notify = (message, type = "success") => {
    const position = "top-right"; 
    if (type === "success") {
        toast.success(message, { position });
    } else if (type === "error") {
        toast.error(message, { position });
    }
};

export default Notification;
