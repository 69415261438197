import React from "react";
import Button from "../Button/Button";

import "./Header.scss";
const Header = () => {
    return (
        <header className="header">
            <section>
                <div className="menu-wrapper">
                    <div className="menu-container container">
                        <nav className="menu">
                            <a href="/" className="header-item menu__logo"></a>
                            <div className="header-item menu__list-items">
                                <a className="menu__item" href="#about">
                                    Хто ми
                                </a>
                                <a className="menu__item" href="#proposal">
                                    Що ми пропонуємо
                                </a>
                                <a className="menu__item" href="#vacancies">
                                    Вакансії
                                </a>
                                <a className="menu__item" href="#candidate">
                                    Наші очікування
                                </a>
                                <a className="menu__item" href="#advantages">
                                    Переваги
                                </a>
                            </div>
                            <div className="header-item menu__button-wrapper">
                                <Button
                                    label="Залишити заявку"
                                    variant="transparent"
                                />
                            </div>
                        </nav>
                        <div className="hamburger">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </section>
        </header>
    );
};
export default Header;
