import React from "react";
import "./Candidate.scss";

import Button from "../../components/Button/Button";

const Candidate = () => {
    return (
        <section className="candidate" id="candidate">
            <div className="container candidate__container">
                <div className="candidate__inner">
                    <h2 class="candidate__title title">
                        Наш ідеальний кандидат
                    </h2>
                    <ul class="candidate__list">
                        <li class="candidate__item">
                            <span className="candidate__item-icon"></span>
                            <span className="candidate__item-title">
                                Вік від 18 до 30 років
                            </span>
                            <span className="candidate__description">
                                Ми в пошуках амбітних кандидатів, які націлені
                                на високий дохід та прагнуть стати
                                TOP-менеджером у сфері продажів 
                            </span>
                        </li>
                        <li class="candidate__item">
                            <span className="candidate__item-icon"></span>
                            <span className="candidate__item-title">
                                Пунктуальність та відповідальність
                            </span>
                            <span className="candidate__description">
                                Ми в пошуках амбітних кандидатів, які націлені
                                на високий дохід та прагнуть стати
                                TOP-менеджером у сфері продажів 
                            </span>
                        </li>
                        <li class="candidate__item">
                            <span className="candidate__item-icon"></span>
                            <span className="candidate__item-title">
                                Відмінні комунікативні навички
                            </span>
                            <span className="candidate__description">
                                Для ефективного спілкування з клієнтами потрібно
                                мати чітку та грамотну комунікацію, враховувати
                                потреби клієнта, а також вміти слухати і
                                пропонувати відповідні рішення.
                            </span>
                        </li>
                        <li class="candidate__item">
                            <span className="candidate__item-icon"></span>
                            <span className="candidate__item-title">
                                Самомотивованість, ініціативність  та гнучкість
                            </span>
                        </li>
                        <li class="candidate__item">
                            <span className="candidate__item-icon"></span>
                            <span className="candidate__item-title">
                                Високий рівень енергії та позитивного
                                налаштування 
                            </span>
                        </li>
                    </ul>
                </div>

                <Button label="Залишити заявку"  />
            </div>
        </section>
    );
};
export default Candidate;
