import React from "react";
import Button from "../../components/Button/Button";
import "./Hero.scss";

const Hero = () => {
    return (
        <section className="hero">
            <div className="hero__wrapper">
                <div className="container hero__container">
                    <div className="hero__top">
                        <div className="hero__top-more"></div>
                        <div className="hero__title-wrapper">
                            <h1 className="hero__title title">
                                <span class="hero__title-word">ШУКАЄШ</span>
                                <span class="hero__title-word">РОБОТУ?</span>
                            </h1>
                        </div>
                        <div className="hero__scroll-bottom"></div>
                    </div>
                    <div className="hero__bottom" id="about">
                        <div className="hero__bottom-inner">
                            <div className="hero__bottom-inner-item">
                                <div className="hero__bottom-info">
                                    <div className="hero__bottom-title">
                                        Ми — <strong>Trinity Group</strong>
                                    </div>
                                    <div className="hero__bottom-description">
                                        Вже більше 4 років на ринку, наші
                                        клієнти з різних куточків світу
                                        довіряють нашому досвіду в питаннях
                                        інформаційної підтримки. Кількість
                                        клієнтів постійно зростає і нам потрібен
                                        Менеджер  по роботі з клієнтами.
                                    </div>
                                </div>
                            </div>
                            <div className="hero__bottom-inner-item">
                                <div className="hero__bottom-inner-head"></div>
                                <div className="hero__bottom-inner-slash"></div>
                                <div className="hero__bottom-logo"></div>
                            </div>
                            <div className="hero__bottom-inner-item">
                                <div className="hero__bottom-decoration"></div>
                            </div>
                            <div className="hero__bottom-inner-item">
                                <div className="hero__bottom-button-container">
                                    <Button label="Залишити заявку" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero__matrix-up"></div>
            <div className="hero__matrix-down"></div>
        </section>
    );
};
export default Hero;
