import React from "react";
import Button from "../../components/Button/Button.js";
import "./Request.scss";
const Request = () => {
    return (
        <section className="request">
            <div className="container request__container">
                <div className="request__inner">
                    <div className="request__top">
                        <h2 className="request__bottom-title title">
                            Записуйся на співбесіду з нашим HR-менеджером онлайн
                        </h2>
                    </div>
                    <div className="request__bottom">
                        <div className="request__bottom-wrapper">
                            <div className="request__bottom-container-border">
                                <div className="request__bottom-container">
                                    Щоб ми змогли тебе проінформувати
                                    <br className="desktop-word-break" /> по
                                    актуальним вакансіям та зорієнтували
                                    <br className="desktop-word-break" /> по
                                    всім питанням, що в тебе виникають
                                </div>
                            </div>
                            <div className="request__buttons-wrapper">
                                <div className="request__button-arrow">
                                    <Button />
                                </div>
                                <div className="request__button-main">
                                    <Button label="Залишити заявку" />
                                </div>
                            </div>
                        </div>
                        <div className="request__button-main--mobile">
                            <Button label="Залишити заявку" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Request;
