import React, { useEffect } from "react";
import "./Footer.scss";
const Footer = () => {
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const telegramLink =
                            document.querySelector(".telegram-link");
                        if (telegramLink) {
                            telegramLink.classList.add("hover");
                            setTimeout(() => {
                                telegramLink.classList.remove("hover");
                            }, 2000);
                        }
                    }
                });
            },
            { rootMargin: "0px" }
        );

        observer.observe(document.querySelector(".telegram-link"));

        return () => observer.disconnect();
    }, []);

    return (
        <footer className="footer">
            <div className="footer__container">
                <div className="footer__top">
                    <a
                        className="telegram-link"
                        href="https://t.me/hrdmoney"
                        target="_blank"
                    >
                        @hrdmoney
                    </a>
                    <div className="footer__top-border  footer__top-border--left"></div>
                    <div className="footer__top-border  footer__top-border--right"></div>
                </div>
                <div className="footer__bottom">
                    <div className="footer__nav">
                        <a href="#about" className="footer__nav-item">
                            <div className="footer__nav-item-number">01</div>
                            <div className="footer__nav-item-name">хто ми</div>
                        </a>
                        <a href="#proposal" className="footer__nav-item">
                            <div className="footer__nav-item-number">02</div>
                            <div className="footer__nav-item-name">
                                ми пропонуємо
                            </div>
                        </a>
                        <a href="#vacancies" className="footer__nav-item">
                            <div className="footer__nav-item-number">03</div>
                            <div className="footer__nav-item-name">
                                вакансії
                            </div>
                        </a>
                        <a href="#candidate" className="footer__nav-item">
                            <div className="footer__nav-item-number">04</div>
                            <div className="footer__nav-item-name">
                                очікування
                            </div>
                        </a>
                        <a href="#advantages" className="footer__nav-item">
                            <div className="footer__nav-item-number">05</div>
                            <div className="footer__nav-item-name">
                                переваги
                            </div>
                        </a>
                    </div>
                    <div className="footer__bottom-border  footer__bottom-border--left"></div>
                    <div className="footer__bottom-border  footer__bottom-border--right"></div>
                </div>
            </div>
        </footer>
    );
};
export default Footer;
