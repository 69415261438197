import React, { useEffect } from "react";
import "./Advantages.scss";

const Advantages = () => {
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const items =
                            document.querySelectorAll(".advantages__item");
                        items.forEach((item, index) => {
                            setTimeout(() => {
                                item.classList.add("show");

                                // Если это последний элемент, подсветить текст
                                if (index === items.length - 1) {
                                    const highlighter =
                                        document.querySelectorAll(
                                            ".earn-highlighter"
                                        );
                                    highlighter.forEach((el) => {
                                        el.classList.add("highlight");
                                        setTimeout(() => {
                                            el.classList.remove("highlight");
                                        }, 2000); // Время, на которое изменяется цвет
                                    });
                                }
                            }, index * 500);
                        });
                    }
                });
            },
            { rootMargin: "20px" }
        );

        observer.observe(document.querySelector("#advantages"));

        return () => observer.disconnect();
    }, []);

    return (
        <section className="advantages" id="advantages">
            <div className="container advantages__container">
                <div className="advantages__inner">
                    <h2 class="advantages__title title">
                        Переваги <br /> працевлаштування до нас
                    </h2>
                    <ul class="advantages__list">
                        <li class="advantages__item">
                            <span className="advantages__item-number">01</span>
                            <p className="advantages__item-title">
                                Беремо без досвіду!
                            </p>
                            <div className="advantages__description">
                                <p>
                                    Наша  досвідчена команда професійно навчає
                                    всім тонкощам у сфері продажів та ділиться
                                    своїм досвідом.
                                </p>
                                <p>
                                    Курс навчання триває 5 днів та поєднує в
                                    собі теоретичну і практичну частину. А також
                                    він є оплачуваним !
                                </p>
                            </div>
                        </li>
                        <li class="advantages__item">
                            <span className="advantages__item-number">02</span>
                            <p className="advantages__item-title">
                                Висока заробітна плата{" "}
                                <span className="earn-highlighter">
                                    від 2500$
                                </span>
                            </p>
                            <div className="advantages__description">
                                <p>
                                    У всіх наших працівників є фіксована ставка
                                    32000 грн (яка зростає при виконанні
                                    місячного плану), а також ви отримуєте
                                    високий  бонус за кожну заключену  угоду.
                                </p>
                                <p>
                                    Додатково у нас є велика сітка різних промо
                                </p>
                                <p>
                                    Виплата заробітної плати відбувається
                                    щомісяця з 10-го по 15-е, а також аванс з
                                    25-го по 30-е.
                                </p>
                            </div>
                        </li>
                        <li class="advantages__item">
                            <span className="advantages__item-number">03</span>
                            <p className="advantages__item-title">
                                Зручний графік <br /> з 10 до 19 пн-пт 5\2
                            </p>
                            <div className="advantages__description">
                                <p>
                                    Ми дотримуємось встановленого графіку
                                    роботи, з обідньою перервою та 3 coffee
                                    break
                                </p>
                            </div>
                        </li>
                        <li class="advantages__item">
                            <span className="advantages__item-number">04</span>
                            <p className="advantages__item-title">
                                Кар’єрний зріст
                            </p>
                            <div className="advantages__description">
                                <p>
                                    Компанія зацікавлена в вашому розвитку разом
                                    з нами, тому з радістю допоможемо
                                    реалізувати твої амбіції
                                </p>
                            </div>
                        </li>
                        <li class="advantages__item advantages__item-decoration"></li>
                    </ul>
                </div>
            </div>
        </section>
    );
};
export default Advantages;
