document.addEventListener("DOMContentLoaded", () => {
    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    const scrollButton = document.querySelector(".scroll-to-top");

    window.addEventListener("scroll", () => {
        if (window.scrollY > 100) {
            scrollButton.style.display = "block";
        } else {
            scrollButton.style.display = "none";
        }
    });

    scrollButton.addEventListener("click", scrollToTop);

    // Знаходження всіх анкорних посилань
    const anchorLinks = document.querySelectorAll('a[href^="#"]');

    // Додавання обробника подій для кожного анкорного посилання
    anchorLinks.forEach((link) => {
        link.addEventListener("click", (event) => {
            event.preventDefault(); // Заборонити стандартну дію посилання
            const targetId = link.getAttribute("href"); // Отримати ідентифікатор цільового елемента
            const targetElement = document.querySelector(targetId); // Знайти цільовий елемент
            if (targetElement) {
                // Плавна прокрутка до цільового елемента
                targetElement.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }
        });
    });

    function smoothScroll(target, focusElement) {
        const scrollElement = document.querySelector(target);
        const focusTarget = document.querySelector(focusElement);

        if (scrollElement && focusTarget) {
            const scrollOptions = {
                behavior: "smooth",
                block: "start",
            };

            // Використовуємо requestAnimationFrame для плавної анімації
            const animateScroll = () => {
                scrollElement.scrollIntoView(scrollOptions);
                setTimeout(() => {
                    focusTarget.focus(); // Додаємо фокус на поле вводу після затримки
                }, 600); // Затримка в мілісекундах (наприклад, 600 мс)
            };

            if ("requestAnimationFrame" in window) {
                requestAnimationFrame(animateScroll);
            } else {
                // Якщо requestAnimationFrame не підтримується, просто прокручуємо без анімації
                scrollElement.scrollIntoView(scrollOptions);
                focusTarget.focus();
            }
        }
    }

    // document.addEventListener("DOMContentLoaded", function () {
    const registrationLinks = document.querySelectorAll(".registration-link");
    if (registrationLinks) {
        registrationLinks.forEach(function (link) {
            link.addEventListener("click", function (event) {
                event.preventDefault();
                smoothScroll("#registration", "#registration-anchor");
            });
        });
    }

    // });

    const hamburger = document.querySelector(".hamburger");
    const menu = document.querySelector(".menu__list-items");
    const logo = document.querySelector(".menu__logo");

    hamburger.addEventListener("click", function () {
        menu.classList.toggle("menu__active");
        hamburger.classList.toggle("hamburger__active");
    });
    // Закрытие меню при клике за его пределами
    document.addEventListener("click", function (event) {
        if (!menu.contains(event.target) && !hamburger.contains(event.target)) {
            menu.classList.remove("menu__active");
            hamburger.classList.remove("hamburger__active");
        }

        if (menu.classList.contains("menu__active")) {
            logo.classList.add("menu__logo-icon"); // Додаємо клас "menu__logo-purple"
        } else {
            logo.classList.remove("menu__logo-icon"); // Видаляємо клас "menu__logo-purple"
        }
    });

    window.addEventListener("scroll", function () {
        const scrollDistance = window.innerHeight; // Высота экрана
        const opacityChangePoint = 0; // Начало изменения прозрачности
        const overlay = document.querySelector(".hero__top-more");

        if (overlay) {
            if (window.scrollY > opacityChangePoint) {
                const opacity = Math.max(
                    0,
                    1 -
                        (window.scrollY - opacityChangePoint) /
                            (scrollDistance - opacityChangePoint)
                );
                overlay.style.opacity = opacity;
            } else {
                overlay.style.opacity = 1;
            }
        }
    });

    window.addEventListener("scroll", () => {
        const scrollValue = window.scrollY;

        const matrixUp = document.querySelector(".hero__matrix-up");
        const matrixDown = document.querySelector(".hero__matrix-down");

        if (matrixUp) {
            matrixUp.style.transform = `translateY(${scrollValue * 0.2}px)`;
        }

        if (matrixDown) {
            matrixDown.style.transform = `translateY(-${scrollValue * 0.2}px)`;
        }
    });

    window.addEventListener("scroll", () => {
        const scrollValue = window.scrollY;

        const matrixUp = document.querySelector(".proposal__matrix-up");
        const matrixDown = document.querySelector(".proposal__matrix-down");

        if (matrixUp) {
            matrixUp.style.transform = `translateY(${scrollValue * 0.2}px)`;
        }

        if (matrixDown) {
            matrixDown.style.transform = `translateY(-${scrollValue * 0.2}px)`;
        }
    });
});
