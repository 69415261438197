import React, { useEffect } from "react";
import "./Vacancies.scss";

const Vacancies = () => {
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const items =
                            document.querySelectorAll(".vacancies__item");
                        items.forEach((item, index) => {
                            setTimeout(() => {
                                item.classList.add("show");
                            }, index * 500); // Появление по очереди
                        });
                    }
                });
            },
            { rootMargin: "-300px" }
        );

        observer.observe(document.querySelector("#vacancies"));

        return () => observer.disconnect();
    }, []);

    return (
        <section className="vacancies" id="vacancies">
            <div className="container vacancies__container">
                <div className="vacancies__inner">
                    <h2 className="vacancies__title title">
                        Найкращі вакансії
                    </h2>
                    <ul className="vacancies__list">
                        <li className="vacancies__item">
                            <span className="vacancies__item-number">01.</span>
                            <span>Спеціаліст підтримки</span>
                        </li>
                        <li className="vacancies__item">
                            <span className="vacancies__item-number">02.</span>
                            <span>Фінансовий аналітик</span>
                        </li>
                        <li className="vacancies__item">
                            <span className="vacancies__item-number">03.</span>
                            <span>Менеджер по роботі з клієнтами</span>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Vacancies;
