import React, { useState, useEffect } from "react";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./RegistrationForm.scss";
import { notify } from "../Notification/Notification";

const RegistrationForm = () => {
    const [countryCode, setCountryCode] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        axios
            .get("https://ipapi.co/json/")
            .then((response) => {
                const countryCode = response.data.country_code.toLowerCase();
                setCountryCode(countryCode);
            })
            .catch((error) => {
                console.error("Error fetching IP data:", error);
            });
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        const formData = new FormData(event.target);

        axios
            .post("https://trinity-managment.com/send", {
                name: formData.get("name"),
                surname: formData.get("surname"),
                phone: formData.get("phone"),
            })
            .then((response) => {
                console.log(response.data.message);
                notify(response.data.message);
                setIsSubmitted(true);
            })
            .catch((error) => {
                console.error("Error sending email:", error);
                notify("Помилка. Спробуйте пізніше ☹️", "error");
                setIsSubmitting(false);
            });
    };

    return (
        <section className="registration" id="registration">
            <div className="registration-container container">
                <div className="form-registration">
                    <h3 className="form-title title">Час обирати!</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="form-registration__fields-wrapper">
                            <input
                                id="registration-anchor"
                                type="text"
                                name="name"
                                placeholder="Повне імʼя"
                                required
                            />
                            <input
                                type="text"
                                name="surname"
                                placeholder="Ім'я користувача Telegram"
                                required
                            />
                            <PhoneInput
                                country={countryCode}
                                inputProps={{
                                    name: "phone",
                                    required: true,
                                    autoFocus: false,
                                }}
                            />
                        </div>
                        <div className="button-wrapper">
                            <button
                                className={`button ${
                                    isSubmitted
                                        ? "button--transparent button--disabled"
                                        : ""
                                }`}
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitted ? (
                                    <span className="successful-notify-text">
                                        Відправлено
                                    </span>
                                ) : (
                                    "Відправити"
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default RegistrationForm;
