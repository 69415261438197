import React from "react";
import Footer from "./components/Footer/Footer.js";
import Header from "./components/Header/Header.js";
import ScrollTop from "./components/ScrollTop/ScrollTop.js";
import RegistrationForm from "./components/RegistrationForm/RegistrationForm.js";
import Notification from "./components/Notification/Notification.js";

import Hero from "./sections/Hero/Hero.js";
import Proposal from "./sections/Proposal/Proposal.js";
import Request from "./sections/Request/Request.js";
import Vacancies from "./sections/Vacancies/Vacancies.js";
import Candidate from "./sections/Candidate/Candidate.js";
import Advantages from "./sections/Advantages/Advantages.js";

import "./assets/scripts/script.js";

const App = () => (
    <div>
        <Header />

        <Hero />
        <Proposal />
        <Request />
        <Vacancies />
        <Candidate />
        <Advantages />
        <RegistrationForm />
        <Notification />

        <Footer />

        <ScrollTop />
    </div>
);

export default App;
