import React, { useEffect } from "react";
import "./Proposal.scss";
const Proposal = () => {
    
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const items = document.querySelectorAll(
                            ".proposal__right-item"
                        );
                        items.forEach((item, index) => {
                            setTimeout(() => {
                                item.classList.add("show");
                            }, index * 500); // Появление по очереди
                        });
                    }
                });
            },
            { rootMargin: "-300px" }
        );

        observer.observe(document.querySelector("#proposal"));

        return () => observer.disconnect();
    }, []);

    return (
        <section className="proposal" id="proposal">
            <div className="container proposal__container">
                <div className="proposal__left">
                    <div className="proposal__matrix-up"></div>
                    <div className="proposal__matrix-down"></div>
                </div>
                <div className="proposal__right">
                    <h2 className="proposal__right-title title">
                        Ми пропонуємо
                    </h2>
                    <ul className="proposal__right-list">
                        <li className="proposal__right-item">
                            Роботу  в сучасному офісі Києва
                        </li>
                        <li className="proposal__right-item">
                            З графіком з Пн по Пт з 10 до 19{" "}
                        </li>
                        <li className="proposal__right-item">
                            Середньою З/П наших співробітників від 2500$
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};
export default Proposal;
